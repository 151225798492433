import Lottie from "react-lottie";

const DisplayLottie = ({ animationPath }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: animationPath,
  };
  return <Lottie options={defaultOptions} />;
};

export default DisplayLottie;
