import React, { useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFormSubmit from "../hooks/useFormSubmit";
import { GOOGLE_SITE_KEY } from "../constants";
import { URL_ADD_CONTACTME } from "../constants/urls";

const ContactMe = () => {
  const reCaptchaRef = useRef(null);
  const { isLoading, response, submit } = useFormSubmit();
  const formik = useFormik({
    initialValues: { fullName: "", email: "", message: "", recaptcha: "" },
    onSubmit: (values) => {
      reCaptchaRef.current.reset();
      submit(URL_ADD_CONTACTME, values);
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Required"),
      email: Yup.string().required("Required").email("Invalid email address"),
      message: Yup.string().required("Required"),
      recaptcha: Yup.string()
        .min(1, "Prove you are not a robot")
        .required("Prove you are not a robot"),
    }),
  });

  useEffect(() => {
    if (response !== null) {
      if (response.type === "success") {
        formik.resetForm();
      }
      //alert(response.message);
    }
  }, [response]);

  return (
    <section className="section section-lg" id="contact-section">
      <Container>
        <Row className="justify-content-center">
          <Col lg="8">
            <Card className="bg-gradient-secondary shadow">
              <CardBody className="p-lg-5">
                <h4 className="mb-1">Want to work with me?</h4>
                <p className="mt-0">Your project is very important to me.</p>
                <Form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-user-run" />
                      </InputGroupText>

                      <Input
                        placeholder="Your name"
                        type="text"
                        id="fullName"
                        name="fullName"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps("fullName")}
                        invalid={
                          formik.touched.fullName && formik.errors.fullName
                            ? true
                            : false
                        }
                      />
                      <FormFeedback>{formik.errors.fullName}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                      <Input
                        placeholder="Email address"
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        {...formik.getFieldProps("email")}
                        invalid={
                          formik.touched.email && formik.errors.email
                            ? true
                            : false
                        }
                      />
                      <FormFeedback>{formik.errors.email}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Input
                      className="form-control-alternative"
                      cols="80"
                      name="name"
                      placeholder="Type a message..."
                      rows="4"
                      type="textarea"
                      onChange={formik.handleChange}
                      {...formik.getFieldProps("message")}
                      invalid={
                        formik.touched.message && formik.errors.message
                          ? true
                          : false
                      }
                    />
                    <FormFeedback>{formik.errors.message}</FormFeedback>
                  </FormGroup>
                  <div>
                    <ReCAPTCHA
                      sitekey={GOOGLE_SITE_KEY}
                      ref={reCaptchaRef}
                      value={formik.values.recaptcha}
                      onBlur={formik.handleBlur}
                      onChange={async () => {
                        const token = await reCaptchaRef.current.getValue();
                        formik.setFieldValue("recaptcha", token);
                      }}
                    />
                    {formik.touched.recaptcha && formik.errors.recaptcha ? (
                      <div class="invalid-div">{formik.errors.recaptcha}</div>
                    ) : (
                      ""
                    )}
                    {response && response.type === "error" && (
                      <UncontrolledAlert color="danger">
                        <span className="alert-inner--icon">
                          <i class="fa fa-exclamation-triangle" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          {response.message}
                        </span>
                      </UncontrolledAlert>
                    )}
                    {response && response.type === "success" && (
                      <UncontrolledAlert color="success">
                        <span className="alert-inner--icon">
                          <i class="fa fa-check" />
                        </span>{" "}
                        <span className="alert-inner--text">
                          {response.message}
                        </span>
                      </UncontrolledAlert>
                    )}
                    {isLoading ? (
                      <Spinner color="success">Loading...</Spinner>
                    ) : (
                      <Button
                        block
                        className="btn-round"
                        color="default"
                        size="lg"
                        type="submit"
                      >
                        Send Message
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactMe;
