import { Container, Row, Col } from "reactstrap";
import ExperienceItem from "../components/ExperienceItem";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
const Experience = ({ experienceList }) => {
  return (
    <>
      <section id="experience-section" className="section">
        <Container className="text-center">
          <Row className="row-grid align-items-center">
            <Col lg="12">
              <h1 className="h1">Experience</h1>
              <VerticalTimeline lineColor="#e5e7eb">
                {experienceList.map((item) => (
                  <ExperienceItem key={item.desc} {...item} />
                ))}
              </VerticalTimeline>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Experience;
