import Hero from "../views/Hero";
import Navigation from "../components/Navigation";
import { SEO } from "../components/SEO";
import Skills from "../views/Skills";
import {
  SkillBars,
  educationList,
  experienceList,
  heroData,
  skillsSection,
  socialLinks,
} from "../data/profile";
import Footer from "../components/Footer";
import Experience from "../views/Experience";
import Proficiency from "../views/Proficiency";
import Education from "../views/Education";
import ContactMe from "../views/ContactMe";

const Home = () => {
  return (
    <>
      <SEO />
      <Navigation socialLinks={socialLinks} brand={heroData.name} />
      <Hero data={heroData} />
      <Skills skillsSection={skillsSection} />
      <Proficiency SkillBars={SkillBars} />
      <Experience experienceList={experienceList} />
      <Education educationList={educationList} />
      <ContactMe />
      <Footer />
    </>
  );
};

export default Home;
