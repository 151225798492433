import React, { Fragment } from "react";
import { Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import DisplayLottie from "../components/DisplayLottie";

const Skills = ({ skillsSection }) => {
  return (
    skillsSection && (
      <section className="section " id="skills-section">
        <Container className="text-center my-1 section section-md">
          <h1 className="h1">{skillsSection.title}</h1>
          {/* <p className="lead">{skillsSection.subTitle}</p> */}
          {skillsSection.data.map((section, index) => {
            return (
              <Row className="my-5" key={index}>
                <Col lg="6" className="order-2 order-lg-1">
                  <DisplayLottie animationPath={section.lottieAnimationFile} />
                </Col>
                <Col lg="6" className="order-1 order-lg-2">
                  <h3 className="h3 mb-2">{section.title}</h3>
                  <div className="d-flex justify-content-center flex-wrap mb-2">
                    {section.softwareSkills.map((skill, i) => {
                      return (
                        <Fragment key={i}>
                          <div
                            className="icon icon-lg icon-shape shadow-sm rounded-circle m-1"
                            id={`tooltip-s${i}`}
                          >
                            <img
                              width="48"
                              height={skill.skillName === "Azure" ? "" : "48"}
                              src={require(`../assets/img/icons/skills/${skill.fileName}`)}
                            />
                          </div>
                          <UncontrolledTooltip
                            delay={0}
                            placement="bottom"
                            target={`tooltip-s${i}`}
                          >
                            {skill.skillName}
                          </UncontrolledTooltip>
                        </Fragment>
                      );
                    })}
                  </div>
                  <div>
                    {section.skills.map((skill, i) => {
                      return (
                        <p key={i}>
                          <i className="ni ni-check-bold"></i> {skill}
                        </p>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            );
          })}{" "}
        </Container>
      </section>
    )
  );
};

export default Skills;
