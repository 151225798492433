import React from "react";
import {
  Card,
  CardBody,
  Badge,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
} from "reactstrap";

const EducationItem = ({ name, title, duration, desc, grade, logo, link }) => {
  return (
    <Col lg="6">
      <Card className="card-lift--hover  text-center shadow border-0 h-100 ">
        <CardBody>
          <a href={link} target="_blank">
            <img
              width="64"
              height="64"
              alt={logo}
              className="rounded-circle"
              src={require(`../assets/img/icons/${logo}`)}
            />
          </a>
          <CardTitle tag="h4" className="mb-2">
            {name}
          </CardTitle>
          <CardSubtitle tag="h5" className="mb-2">
            {title}
          </CardSubtitle>

          <CardText tag="div" className="description my-3 text-left">
            {desc}
          </CardText>
          <div>
            <Badge color="success" pill className="mr-1">
              {duration}
            </Badge>
            {grade && (
              <Badge color="primary" pill className="mr-1">
                {grade}
              </Badge>
            )}
          </div>
        </CardBody>
      </Card>{" "}
    </Col>
  );
};

export default EducationItem;
