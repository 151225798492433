import { Container, Row, Col } from "reactstrap";
import EducationItem from "../components/EducationItem";
const Education = ({ educationList }) => {
  return (
    <>
      <div className="position-relative">
        {/* shape Hero */}
        <section
          id="education-section"
          className="section section-lg section-shaped pb-250"
        >
          <div className="shape bg-gradient-green">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="text-center">
            <h1 className="display-3 text-white">Education</h1>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        {/* 1st Hero Variation */}
      </div>

      <section className="section section-lg pt-lg-0 mt--200">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                {educationList.map((item, i) => (
                  <EducationItem key={item.name} {...item} />
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Education;
