import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { MdOutlineWorkOutline } from "react-icons/md";

const ExperienceItem = ({
  companyLogo,
  company,
  companyLink,
  companyColor,
  role,
  date,
  desc,
  descBullets,
}) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: "#f3f4f6",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.05)",
        textAlign: "left",
        padding: "1.3rem 2rem",
      }}
      contentArrowStyle={{ borderRight: "0.4rem solid #9ca3af" }}
      date={date}
      iconStyle={{ background: "white", fontSize: "1.5rem" }}
      icon={<MdOutlineWorkOutline />}
    >
      <h5 className="font-semibold capitalize"> {role}</h5>

      <h6 className="font-normal !mt-0">
        <a href={companyLink} target="_blank">
          {company}
        </a>
      </h6>

      <p>{desc}</p>
    </VerticalTimelineElement>
  );
};

export default ExperienceItem;
