import React from "react";
import { Container, Row, Progress, Col } from "reactstrap";
import DisplayLottie from "../components/DisplayLottie";

const Proficiency = ({ SkillBars }) => {
  return (
    SkillBars && (
      <section className="section bg-secondary">
        <Container>
          <Row className="row-grid align-items-center">
            <Col lg="6">
              <h1 className="h1">Proficiency</h1>
              {SkillBars.map((skill) => {
                return (
                  <div className="progress-info" key={skill.Stack}>
                    <div className="progress-label">
                      <span>{skill.Stack}</span>
                    </div>
                    <div className="progress-percentage">
                      <span>{skill.progressPercentage}%</span>
                    </div>
                    <Progress
                      max="100"
                      value={skill.progressPercentage}
                      role="progressbar"
                      aria-label={skill.Stack}
                    />
                  </div>
                );
              })}
            </Col>
            <Col lg="6">
              <DisplayLottie animationPath="/lottie/prof.json" />
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

export default Proficiency;
