import axios from "axios";
import { useState } from "react";

const useFormSubmit = () => {
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const submit = async (url, data) => {
    setLoading(true);
    setResponse(null);
    try {
      const response = await axios({
        method: "post",
        url: url,
        data: data,
        headers: { "Content-Type": "application/json; charset=UTF-8" },
      });

      setResponse({
        type: "success",
        message: response.data,
      });
    } catch (error) {
      setResponse({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit };
};
export default useFormSubmit;
